<router-outlet></router-outlet>
<div class="loader">
  <spinner></spinner>
</div>
<div class="lazy-module-backdrop" [class.active]="loadingRouteConfig"></div>
@if (loadingRouteConfig) {
  <div>
    <spinner class="lazy-module-spinner"></spinner>
  </div>
}
