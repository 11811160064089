import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  DestroyRef,
  Inject,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { HelpScoutBeaconsService } from './help-scout-beacons.service';
import * as NgrxSharedActions from './shared/ngrx/shared.actions';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { selectPartner } from './shared/ngrx/shared.selectors';
import { State } from './reducers';
import { NO_PARTNER, Partner, PARTNERS } from './shared/Models/partners';
import { User } from './shared/Models/user';
import { ThemeLoaderService } from './shared/Services/theme-loader/theme-loader.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpinnerComponent } from './shared/Components/spinner/spinner.component';
import { ScriptLoaderService } from './shared/Services/script-loader/script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, SpinnerComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  loadingRouteConfig: boolean;
  user: User;

  partner$ = this.store.select(selectPartner);
  user$ = this.store.select(selectUser);

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private overlayContainer: OverlayContainer, // built into Angular Material
    private destroyRef: DestroyRef,
    private router: Router,
    private store: Store<State>,
    private themeLoader: ThemeLoaderService,
    private titleService: Title,
    public viewContainerRef: ViewContainerRef,
    private helpBeacons: HelpScoutBeaconsService, // DO NOT REMOVE pls
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });
  }

  ngOnInit(): void {
    this.user$
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((usr) => (this.user = usr));
    this.partner$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((part) => this.partnerHandler(part));
  }

  createThemeSwitcher() {
    const paletteSelector = document.createElement(`div`);
    paletteSelector.style.position = 'absolute';
    paletteSelector.style.width = '200px';
    paletteSelector.style.top = '0';
    paletteSelector.style.left = `${window.innerWidth - 200}px`;
    paletteSelector.style.minHeight = '50px';
    paletteSelector.style.backgroundColor = 'lightgray';
    paletteSelector.draggable = true;
    paletteSelector.style.zIndex = 10000000 + '';

    paletteSelector.addEventListener(
      `drag`,
      (evt) => {
        evt.stopPropagation();
        if (evt.screenX !== 0) {
          paletteSelector.style.top = `${evt.clientY}px`;
          paletteSelector.style.left = `${evt.clientX - 80}px`;
        }
      },
      false,
    );

    const clearThemeBtn = document.createElement(`button`);
    clearThemeBtn.innerText = 'Toggle theme';
    clearThemeBtn.style.width = '100%';
    clearThemeBtn.style.background = 'gray';
    clearThemeBtn.style.color = 'white';
    clearThemeBtn.style.height = '50px';
    clearThemeBtn.style.fontSize = '18px';
    clearThemeBtn.style.outline = 'none';

    clearThemeBtn.onclick = () => {
      const part = document.body.classList?.contains(`msm-theme`)
        ? NO_PARTNER
        : PARTNERS.find((p) => p.code === 'msm');
      this.store.dispatch(NgrxSharedActions.setPartner({ partner: part }));
    };

    paletteSelector.append(clearThemeBtn);
    document.body.append(paletteSelector);
  }

  partnerHandler(part: Partner | null): void {
    const classList = document.body.classList;
    const overlayClassList =
      this.overlayContainer.getContainerElement().classList;
    const favicon = this._document.getElementById(`favicon`);

    if (part?.code) {
      classList.add(part.class);
      overlayClassList.add(part.class);
      if (part.code === 'msm') this.themeLoader.init('msm-theme');
    } else {
      this.themeLoader.init(part.class);
      PARTNERS.forEach((p) => {
        if (classList?.contains(p.class)) {
          classList.remove(p.class);
        }
        if (overlayClassList?.contains(p.class)) {
          overlayClassList.remove(p.class);
        }
      });
    }
    this.titleService.setTitle(part.title);
    favicon.setAttribute(`href`, `./assets/favicons/${part.icon}`);
  }
}
