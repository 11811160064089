import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://e6b110c3f33843dc997706d4d1ed4bc1@o97698.ingest.sentry.io/214130',
    integrations: [
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      // new Sentry.Replay(),
    ],
    normalizeDepth: 10,
    tracesSampleRate: 0.5,
    tracePropagationTargets: ['https://api.menutech.com'],
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'Non-Error exception captured',
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP 'optimizing' proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
